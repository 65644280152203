
<template>
    <div class="card-info">
        <div class="header-laebl" >交易金额</div>
        <div >
            <van-field v-model="inputValue"
                       :formatter="amountOfMoneyFormatter"
                       type="number"
                       label="¥"
                       label-width="0.3rem"
                       placeholder="输入金额"
                       input-align="left" class="input-field"/>
        </div>
        <div class="often-cell " style="background-color: #F7F8F9">
            <div class="often-cell-title">常用金额</div>
            <div class="flex justify_content_space_between"  >
                <div @click="inputValue = 100" class="often-cell-btn">100</div>
                <div @click="inputValue = 500" class="often-cell-btn">500</div>
                <div @click="inputValue = 1000" class="often-cell-btn">1000</div>
            </div>
        </div>
        <div class="header-laebl-bottom" >交易类型</div>
        <div class="flex justify_content_space_between group-bg" >
            <div v-for="(thisItem,index1) in arr" :key="index1"
                 @click="cellClick(thisItem)"
                 :class="{'group-cell-btn':!thisItem.isSelcet,'group-cell-btn-select':thisItem.isSelcet}"
            >{{thisItem.dictValue}}
            </div>
        </div>
        <div class="footer">
            <van-button type="info" color="#3F7C53"
                        @click="commitAction">保存</van-button>
        </div>
    </div>
</template>

<script>
    import {Field, Button, Icon, Uploader, Toast} from 'vant';
    import {
        chargeAccount,
        getAccountInfo,
        getCustomerInfo,
        getDictByPage,
        sendBankCardOcr,
        submitCertification
    } from "../../utils/api";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "editTransacrionDetails",
        components: {
            [Field.name]: Field,
            [Button.name]: Button,
            [Icon.name]: Icon,
            [Uploader.name]: Uploader,
        },
        data () {
            return {
                radio:'',
                arr: [],
                newArr:[],
                inputValue:null,
                customerNo: '',
                customerName:'请选择客户',
                transactionID:''
            }
        },
        watch: {
            card (newValue, oldValue) {
                if (newValue.length > oldValue.length) {
                    this.card = newValue.replace(/\s/g, '').replace(/(\d{4})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,})/, '$1 $2 $3 $4 $5')
                } else {
                    this.card = this.card.trim()
                }
            },
            '$route.query.customerNo'(newValue){
                console.log('customerNo',newValue)
                if (newValue){
                    this.customerNo = newValue
                }
            },
            '$route.query.customerName'(newValue){
                console.log('customerName',newValue)
                if (newValue){
                    this.customerName = newValue
                }
            }

        },
        mounted () {
            console.log('mounted')
            this.transactionID = this.$route.query.transactionID;
            if (!this.customerNo){
                this.getPageInfo();
                this.toGetTransactionInfo();
            }
            uwStatisticAction('/stationagent/editTransacrionDetails','修改交易信息')

        },
        methods: {
            cellClick(item){
                var newArr = JSON.parse(JSON.stringify(this.arr))
                for (let thisItem of newArr){
                    thisItem.isSelcet = thisItem.dictKey == item.dictKey;
                    if (thisItem.isSelcet){
                        this.radio = thisItem.dictKey;
                    }
                }
                this.arr = newArr
            },
            toGetTransactionInfo(){
                getAccountInfo({
                    'id':this.transactionID
                }).then(res=>{
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            let item = res.data.accountBook;
                            this.inputValue = item.tranAmount;
                            this.transactionID = item.id;
                            this.idCard = item.idCard;
                            this.radio = item.tranType;
                            this.customerNo = item.customerNo;
                            this.cellClick({'dictKey':item.tranType,})
                        }
                    }
                }).catch(()=>{
                })
            },
            toGetCustomerInfo(){
                getCustomerInfo({
                    'customerNo':this.customerNo
                }).then(res=>{
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            let item = res.data.customerInfo;
                            this.customerName = item.name;
                        }
                    }
                }).catch(()=>{
                })
            },
            getPageInfo () {
                this.arr = JSON.parse(common.getItem('tranType'))
                console.log(this.arr)
                if (this.arr.length > 0){
                    this.radio = this.arr[0].dictKey;
                }
                // this.dataHandling();
            },
            chooseCustomer(){
                this.$router.push({name:'chooseCustomer',query:{type:'editChoose'}})
            },
            dataHandling() {
                let arrLength = this.arr.length; // 数组长度
                let num = 3;  // 每页显示 3 条
                let index = 0;
                for (let i = 0; i < arrLength; i++) {
                    if (i % num === 0 && i !== 0) { // 可以被 3 整除
                        this.newArr.push(this.arr.slice(index, i));
                        index = i;
                    }
                    if ((i + 1) === arrLength) {
                        this.newArr.push(this.arr.slice(index, (i + 1)));
                    }
                }
            },
            amountOfMoneyFormatter(val){
                let sNum = val.toString(); //先转换成字符串类型
                if (sNum.indexOf('.') == 0) {//第一位就是 .
                    console.log('first str is .')
                    sNum = '0' + sNum
                }
                sNum = sNum.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符
                sNum = sNum.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
                sNum = sNum.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
                sNum = sNum.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                if(sNum.indexOf(".")< 0 && sNum !=""){
                    sNum = parseFloat(sNum);
                }
                if (sNum > 9999999.99){
                    sNum = 9999999.99;
                }
                return sNum
            },
            commitAction () {
                if (!this.inputValue) {
                    this.$toast('请填写非零交易金额')
                    return
                }
                if (!common.isAmountOfMoney(this.inputValue)) {
                    this.$toast('金额格式不正确')
                    return
                }
                // if (!this.customerNo) {
                //     this.$toast('请选择客户')
                //     return
                // }
                if (!this.radio) {
                    this.$toast('请选择交易类型')
                    return
                }
                let p = {
                    id: this.transactionID,
                    stationNo: common.getItem('stationNo'),
                    tranAmount: this.inputValue,
                    tranType: this.radio,
                }
                chargeAccount(p).then(res => {
                    if (res.code === 200) {
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data
                        }
                        if (data.businessCode === 200) {
                            this.$router.go(-1)
                        }
                    }
                })
            }
        },
        beforeRouteLeave (to, from, next) {
            if ( to.name == 'chooseCustomer' ){
            } else {
                this.$store.dispatch('keepAliveState/removeKeepAlive', 'editTransacrionDetails')
            }
            next()
        },
    }
</script>

<style lang="less" scoped>
    .card-info {
        background: #F7F8F9;
        height: 100%;
        box-sizing: border-box;
        .card-title {
            padding: 0.3rem 0.3rem 0.2rem;
        }
        .header-laebl{
            background: #FFFFFF;
            color: #333333;
            padding-left: 0.2rem;
            height: 50px;
            line-height: 50px;
        }
        .header-laebl-bottom{
            background: #F7F8F9;
            color: #999999;
            padding-left: 0.2rem;
            height: 40px;
            line-height: 50px;
            font-size: 13px;
        }
        .input-field{
            font-weight: bold;
            font-size: 24px;
            line-height: 1rem
        }
        .often-cell{
            margin: 0 0.2rem;
            .often-cell-title{
                text-align: left;
                line-height: 1rem;
                height: 1rem;
                font-size: 13px;
                color: #999999;
            }
            .often-cell-btn{
                border: solid 1px #dddddd;
                text-align: center;
                height: 40px;
                line-height: 40px;
                border-radius: 4px;
                color: #333333;
                font-size: 15px;
                background-color: #FFFFFF;
                width: 2.2rem;
            }
        }
        .choose-customer-bg{
            background: white;
            .choose-customer-left{
                text-align: left;line-height: 1rem;margin-left: 0.2rem
            }
            .choose-customer-center{
                text-align: right;height: 1rem;line-height: 1rem;color: #999999
            }
            .choose-customer-right{
                text-align: right;margin-right: 0.2rem;
                .choose-customer-right-icon{
                    margin-top: 0.25rem
                }
            }

        }
        .group-bg{
            background-color: #F7F8F9;
            padding-bottom: 0.3rem;
            margin: 0.2rem 0.2rem 0 0.2rem;
            text-align: center;
            flex-wrap:wrap;
            .group-cell-btn{
                width: 2.2rem;
                height: 40px;
                line-height: 40px;
                border-radius: 4px;
                background-color: #FFFFFF;
                margin-bottom: 10px;
            }
            .group-cell-btn-select{
                width: 2.2rem;
                height: 40px;
                line-height: 40px;
                border-radius: 4px;
                background-color: #3F7C53;
                margin-bottom: 10px;
                color: white;
            }
        }
        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            .van-button {
                width: 100%;
            }
        }
    }
</style>
<style lang="less">
    .card-info {

    }
</style>
